/* eslint-disable camelcase */
const indexName = `${process.env.ALGOLIA_PREFIX}Posts`;
const { trimUrl, matchUrlToImage } = require('../../api/Images/MatchModuleImages');

const pageQuery = `{
    posts: allCmsApiPosts {
        edges {
            node {
                id
                acf_data
                post_title
                post_date
                categories {
                    name
                }
            }
        }
    }
    allCmsApiImages(filter: { id: { ne: "dummy" } }) {
        edges {
            node {
                id
                fields {
                    image {
                        localFile {
                            childImageSharp {
                                large: fluid(maxWidth: 500, quality: 60) {
                                    aspectRatio
                                    sizes
                                    src
                                    srcSet
                                    originalImg
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}`;

const productsQuery = ` {
    products: allCmsApiProducts(filter: { id: { ne: "dummy" } }) {
        edges {
            node {
                id
                name
                edition
                main_category_name
                acf_data
                prices
                fields {
                    productImage {
                        localFile {
                            childImageSharp {
                                fluid(maxWidth: 1000, srcSetBreakpoints: [ 500, 750, 1000 ], quality: 60) {
                                    aspectRatio
                                    sizes
                                    src
                                    srcSet
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}`;

const pageToAlgoliaRecord = ({ node: { id, categories, post_date, post_title, acf_data}, images }) => {
    const permalink = JSON.parse(acf_data).permalink;

    const parsedAcf = JSON.parse(acf_data);
    const ingressText = parsedAcf.post_ingress;
    const postSections = parsedAcf.post_sections;
    const allImages = images.edges;
    const imageUrl = trimUrl(parsedAcf.post_image) || '';
    const image = matchUrlToImage({
        imageUrl: trimUrl(imageUrl),
        allImages,
    }).large;

    return {
        objectID: id,
        categoryName: categories[0].name,
        date: post_date,
        title: post_title,
        ingressText,
        postSections,
        permalink,
        image,
    };
};

const productToAlgoliaRecords = ({ node: { id, acf_data, name, main_category_name, fields, prices, edition } }) => {
    const acfData = JSON.parse(acf_data);
    const fullDescription = acfData.product_information.full_description;
    const fullSummary = acfData.product_information.full_summary && acfData.product_information.full_summary.text;
    const image = fields.productImage[0].localFile.childImageSharp;
    const permalink = acfData.permalink;
    const productInfoSections = acfData.product_info_sections;
    const readMore = acfData.product_information.read_more;
    const readMoreFullSummaryText = readMore.full_summary && readMore.full_summary.text;
    const readMoreProductSheetText = readMore.product_sheet && readMore.product_sheet.text;
    const readMoreTableOfContentsText = readMore.table_of_contents && readMore.table_of_contents.text;
    const shortDescription = acfData.product_information.short_description;
    const tableOfContents = acfData.product_information.table_of_contents && acfData.product_information.table_of_contents.text;

    // We split the readMoreTableOfContentsText because it makes products larger than algolia allows.
    const textChunks = ['']; // Important to create an empty string entry if no text
    for (let i = 0, charsLength = readMoreTableOfContentsText.length; i < charsLength; i += 3000) {
        textChunks.push(readMoreTableOfContentsText.substring(i, i + 3000));
    }

    return textChunks.map((textChunk, index) => ({
        objectID: `${id}_${index}`,
        id,
        categoryName: main_category_name,
        title: name,
        link: permalink,
        image,
        shortDescription,
        prices,
        productInfoSections,
        fullDescription,
        fullSummary,
        tableOfContents,
        readMoreFullSummaryText,
        readMoreProductSheetText,
        textChunk,
        edition,
    }));
};

const queries = [
        {
            query: pageQuery,
            transformer: ({ data }) => data.posts.edges.map(edge => {
                return {
                        images: data.allCmsApiImages,
                        ...edge,
                    };
            }).map(pageToAlgoliaRecord),
            indexName,
            settings: { attributesToSnippet: [`excerpt:20`] },
        },
        {
            query: productsQuery,
            transformer: ({ data }) => [].concat.apply([], data.products.edges.map(productToAlgoliaRecords)),
            indexName: `${process.env.ALGOLIA_PREFIX}Products`,
            settings: { 
                attributesToSnippet: [`excerpt:20`], 
                distinct: 1,
                attributeForDistinct: 'id'
            },
        },
];

module.exports = queries;